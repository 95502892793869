<template>
	<div v-if="canSeeOpenPreviewButton">
		<v-tooltip v-if="simpleButtonDisplay && !listTileDisplay" bottom>
			<template v-slot:activator="{ on }">
				<w-btn flat :icon="icon" mini v-bind="$attrs" v-on="on" @click="openPreview()">{{ text }}</w-btn>
			</template>
			<span>{{ text }}</span>
		</v-tooltip>
		<v-list-tile v-if="listTileDisplay" avatar @click="openPreview()">
			<v-list-tile-avatar>
				<v-icon color="primary">{{ icon }}</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ text }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
	</div>
</template>

<script>
import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'
import DocumentEditionService from '@/services/Documents/DocumentEditionService'
import DocumentsManagerService from '@/services/Documents/DocumentsManagerService'
import { mapState } from 'vuex'

import { isEditable, isViewable } from "@/helpers/files";

export default {
	name: 'OpenPreviewButton',
	mixins: [DocumentActionModuleGuard],
	props: {
		openInNewTab: {
			type: Boolean,
			required: false,
			default: false
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: [Array, Object],
			required: false,
			default: () => []
		},
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			isFiltering: state => state.documents.isFiltering
		}),
		model: {
			get: function () {
				return Array.isArray(this.value) ? this.value : [this.value] || []
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		node: function () {
			return this.model.length == 1 ? this.model[0] : null
		},
		document: function () {
			return !this.node?.is_folder ? this.node : null
		},
		icon: function () {
			return this.openInNewTab ? 'open_in_new' : 'fullscreen'
		},
		text: function () {
			return this.openInNewTab ? this.$t('ecm.btn_new_tab_preview') : this.$t('ecm.btn_fullscreen_preview')
		},
		canSeeOpenPreviewButton: function () {
			return this.canOpenPreview(this.document)
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.preview.OPEN_IN_NEW_TAB, action: this.doOpenPreviewInNewTab }]
		},
		isSingleInstanceEventListener: function () {
			return true
		},
		canView: function (document) {
			return document && ((!this.isGuest && isViewable(document.type)) || (this.isGuest && !isEditable(document.type)))
		},
		canEdit: function (document) {
			let result = false
			if (document && this.appService.hasModule('document-edition')) {
				result = DocumentEditionService.isDocumentOnlineEditable(document)
			}
			return result
		},
		canOpenPreview: function (document) {
			return this.canView(document) || this.canEdit(document)
		},
		getDocumentsAvailableForPreview: function (document) {
			let result = []
			if (this.version && document?.latestVersion != this.version) {
				result.push(document)
			} else {
				result.push(...this.selectedFolder?.documents?.filter(folderDocument => this.canOpenPreview(folderDocument)))
			}
			return result
		},
		getOpenPreviewParameters: function (document, selectedFolder, accountingFirmId, vendorId) {
			const params = {}
			if (this.canOpenPreview(document)) {
				this.setOpenPreviewFromRouteParameter(params)
				this.setOpenPreviewDocumentHashParameter(params, document)
				this.setOpenPreviewFolderHashParameter(params, selectedFolder)
				this.setOpenPreviewAccountingFirmParameter(params, accountingFirmId)
				this.setOpenPreviewVendorParameter(params, vendorId)
				this.setOpenPreviewVersionParameter(document, params)
			}
			return params
		},
		setOpenPreviewFromRouteParameter: function (params) {
			params.fromRouteFullpath = this.$route.fullPath
		},
		setOpenPreviewDocumentHashParameter: function (params, document) {
			params.documentHash = document.hash
		},
		setOpenPreviewFolderHashParameter: function (params, selectedFolder) {
			if (selectedFolder) {
				params.folderHash = selectedFolder.hash
			} else if (this.isFiltering) {
				const folder = DocumentsManagerService.findNodeInStructure(this.document.folder_id)
				params.folderHash = folder.hash
			} else {
				params.folderHash = this.selectedFolder.hash
			}
		},
		setOpenPreviewAccountingFirmParameter: function (params, accountingFirmId) {
			if (accountingFirmId) {
				params.accounting_firm_id = accountingFirmId
			} else {
				params.accounting_firm_id = this.accountingFirmId
			}
		},
		setOpenPreviewVendorParameter: function (params, vendorId) {
			if (vendorId) {
				params.vendor_id = vendorId
			} else {
				params.vendor_id = this.vendorId
			}
		},
		setOpenPreviewVersionParameter: function (document, params) {
			if (document?.versionId) {
				params.versionId = document.versionId
			} else if (this.version && this.version.id && this.version.document_id == document.id) {
				params.versionId = this.version.id
			} else if (document?.latestVersion?.id && document?.id == document?.latestVersion?.document_id) {
				params.versionId = document.latestVersion.id
			}
		},
		openPreview: function () {
			return this.doOpenPreview(this.document, this.openInNewTab)
		},
		doOpenPreviewInNewTab: function (event) {
			let currentDocument = {}
			Object.assign(currentDocument, event.document)
			return this.doOpenPreview(event.document, true, event.folder, event.accountingFirmId, event.vendorId)
		},
		doOpenPreview: async function (document, openInNewTab, selectedFolder = null, accountingFirmId = null, vendorId = null) {
			if (!this.canOpenPreview(document)) {
				return;
			}

			if (!openInNewTab) {
				this.eventBus.emit(this.events.preview.OPEN, { accountingFirmId: accountingFirmId, vendorId: vendorId, document: document, folder: selectedFolder })
				return;
			}

			const parameters = this.getOpenPreviewParameters(document, selectedFolder, accountingFirmId, vendorId)
			const goToArgs = {
				name: 'documents-preview-folder',
				params: parameters,
				query: {
					newTab: true
				}
			}

			if (!this.canEdit(document)) {
				this.appService.goToNewTab(goToArgs)
				return;
			}

				const { local, url } = await DocumentEditionService.getDocumentEditionLaunchingOptions(
					this.vendorId,
					document,
					{ id: parameters.versionId },
					this.conditionalDisplay.isMobileApp
				);

				if (local) {
					window.open(url, '_blank');
					return;
				}

				this.appService.goToNewTab(goToArgs);
			

			this.eventBus.emit(this.events.DOCUMENT_OPENED, document)
		}
	}
}
</script>
